import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle03,
  LBanquetContact,
} from '../../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type='ja'
        data={{
          title: {
            main: ' 株主総会',
            sub: (
              <>
                ANNUAL GENERAL MEETING
              </>
            ),
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/plan/stockholders_meeting/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/plan/stockholders_meeting/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className='l_sect02 u_pt70'>
        <LWrap>
          <h2 className="c_headingLv2 u_tac u_mb10">
            アクセスがよく、落ち着いた環境をご用意し皆様をお迎えいたします
          </h2>
          <p className="c_sectLead">
            株主総会は、企業様にとって最も重要なイベントの一つです。<br />
            ロイヤルパークホテルでは、株主様と企業様に満足いただける最適な環境をご用意いたします。<br />
            お客様の信頼と満足を第一に考え、株主総会を成功させるために設備・備品のご用意から当日のサポートまで経験豊富なスタッフが対応いたします。
          </p>
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
